import Head from 'next/head';
import { Landing } from 'views/landing/Landing';
import { useTranslation } from 'react-i18next';

export default function Page() {
  const { t } = useTranslation('meta');
  const pathLang = 'en';
  const pageName = 'landing';

  const title = t(`${pageName}.title`, { lng: pathLang });
  const description = t(`${pageName}.description`, { lng: pathLang });
  const keywords = t(`${pageName}.keywords`, { lng: pathLang });
  const ogImage = t(`${pageName}.ogImage`, { lng: pathLang });
  const canonical = t(`${pageName}.canonical`, { lng: pathLang });

  return (
    <>
      <Head>
        <title>{title}</title>
        <meta property="og:title" key="og:title" content={title} />
        <meta name="description" key="description" content={description} />
        <meta property="og:description" key="og:description" content={description} />
        <meta name="keywords" key="keywords" content={keywords} />
        <meta property="og:url" key="og:url" content={canonical} />
        <link rel="canonical" key="canonical" href={canonical} />
        <meta property="og:image" key="og:image" content={ogImage} />

        <meta property="og:image:type" key="og:image:type" content="image/png" />
        <meta property="og:image:width" key="og:image:width" content="1200" />
        <meta property="og:image:height" key="og:image:height" content="627" />

        {/*<link rel="alternate" href="https://www.engram.us/" hrefLang="en" />*/}
        <link rel="alternate" href="https://www.engram.us/ko" hrefLang="ko" />
        <link rel="alternate" href="https://www.engram.us/ja" hrefLang="ja" />
        <link rel="alternate" href="https://www.engram.us/cn" hrefLang="cn" />
        <link rel="alternate" href="https://www.engram.us/es" hrefLang="es" />
      </Head>
      <Landing />
    </>
  );
}
